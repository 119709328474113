import $ from 'jquery';

let menuIsOpen = false;

const _body = $('body');
const _html = $('html');
let _menuToggle;

function lockBody() {
  _body.addClass('overlay-open');
  _html.addClass('overlay-open');
}

function unlockBody() {
  _body.removeClass('overlay-open');
  _html.removeClass('overlay-open');
}

function toggleMobileMenu(e) {
  e.preventDefault();

  if (menuIsOpen) {
    // menu is open so close it
    // _menuToggle.text('Menu');
    _body.removeClass('mobile-menu--visible').addClass('mobile-menu--hidden');
    menuIsOpen = false;
    unlockBody();
  } else {
    // _menuToggle.text('Close');
    _body.removeClass('mobile-menu--hidden').addClass('mobile-menu--visible');
    menuIsOpen = true;
    lockBody();
  }
}

function init(config) {
  _menuToggle = $(config.mobileMenuTrigger).on('click', toggleMobileMenu);
}

export default { init };
