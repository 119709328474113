import debounce from './debounce';
import externaliseLinks from './externaliseLinks';
import onPageReady from './onPageReady';
import onResize from './onResize';
import openLinksInNewTab from './openLinksInNewTab';

const noop = () => {};

export {
  debounce,
  externaliseLinks,
  onPageReady,
  onResize,
  openLinksInNewTab,
  noop,
};
