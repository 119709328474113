import $ from 'jquery';

function externaliseLinks() {
    let links = [].slice.call($('a'));

    if (links.length > 0) {
        links.forEach(it => {
           if (it.host !== location.host && it.host !== '') {
                it.setAttribute('target', '_blank');
                it.setAttribute('rel', 'noopener noreferrer');
           }
        });
    }
}

export default externaliseLinks;
