import $ from 'jquery';

let readMoreOpen = false;
let _readMoreToggle;

const _readMoreWrapper = $('.u-read-more');

function toggleReadMore(e) {
  e.preventDefault();

  if (readMoreOpen) {
    $('.js-read-more-trigger').text('Read more');
    _readMoreWrapper.removeClass('read-more--visible').addClass('-is-hidden');
    readMoreOpen = false;
  } else {
    $('.js-read-more-trigger').text('Show less');
    _readMoreWrapper.addClass('read-more--visible').removeClass('-is-hidden');
    readMoreOpen = true;
  }
}

function init(config) {
    _readMoreToggle = $(config.readMoreTrigger).on('click', toggleReadMore);
}

export default { init };
